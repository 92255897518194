'use strict';

var app = {
  mobileMenu: function mobileMenu() {
    $('a#reveal-nav').on('click', function (ev) {
      ev.preventDefault();
      $('body').toggleClass('nav-open');
    });
  },
  triggerGame: function triggerGame() {
    $('#trigger-iframe-container').click(function (e) {
      e.preventDefault();
      var container = $('#iframe-container');
      container.slideUp(function () {
        if (container.hasClass('closed') == true) {
          container.slideDown().removeClass('closed');
          $("body").addClass('modal-open');
        } else {
          container.slideUp().addClass('closed');
          $("body").removeClass('modal-open');
        }
      });
    });

    $('#close-iframe-container').click(function (e) {
      e.preventDefault();
      var container = $('#iframe-container');
      container.slideUp(function () {
        if (container.hasClass('closed') == true) {
          container.slideDown().removeClass('closed');
          $("body").addClass('modal-open');
        } else {
          container.slideUp().addClass('closed');
          $("body").removeClass('modal-open');
        }
      });
    });
  },

  tabs: function tabs() {
    $('.nav-tabs > li > a').click(function (event) {

      event.preventDefault();
      var active_tab_selector = $('.nav-tabs > li.active > a').attr('href');
      var actived_nav = $('.nav-tabs > li.active');
      actived_nav.removeClass('active');

      $(this).parents('li').addClass('active');

      $(active_tab_selector).removeClass('active');
      $(active_tab_selector).addClass('hide');

      var target_tab_selector = $(this).attr('href');
      $(target_tab_selector).removeClass('hide');
      $(target_tab_selector).addClass('active');
    });
  },

  thinkDialogue: function thinkDialogue() {
    $('[data-fancybox="gallery"]').fancybox({
      toolbar: true,
      infobar: false,
      arrows: true,
      buttons: ["close"],
      idleTime: false
    });
  },

  textEffect: function textEffect() {

    var TxtRotate = function TxtRotate(el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 15) || 2000;
      this.txt = '';
      this.tick();
      this.isDeleting = true;
    };

    TxtRotate.prototype.tick = function () {
      var i = this.loopNum % this.toRotate.length;
      var fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

      var that = this;
      var delta = 180 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(function () {
        that.tick();
      }, delta);
    };

    window.onload = function () {
      var elements = document.getElementsByClassName('txt-rotate');
      for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-rotate');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
          new TxtRotate(elements[i], JSON.parse(toRotate), period);
        }
      }
      // INJECT CSS
      var css = document.createElement("style");
      css.type = "text/css";
      css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #000 }";
      document.body.appendChild(css);
    };
  },

  showComments: function showComments() {

    $('#trigger-comments').click(function (e) {
      e.preventDefault();
      $('.hidecomments').toggleClass('comments-open');
    });
  },

  init: function init() {
    app.mobileMenu();

    if ($('#iframe-container').length > 0) {
      app.triggerGame();
    }

    if ($('.tabs').length > 0) {
      app.tabs();
    }

    if ($('#thinkFancy').length > 0) {
      app.fancyBox();
    }

    if ($('#thinkDialogue').length > 0) {
      app.thinkDialogue();
    }

    if ($('#file').length > 0) {
      app.formUpload();
    }

    $(".img-gallery").slick({
      appendDots: "#gallerypaging",
      dots: true,
      infinite: false,
      speed: 300,
      fade: true,
      slidesToShow: 1,
      nextArrow: "#nxt-btn",
      prevArrow: "#prev-btn",
      cssEase: "linear"
    });

    $(".contribute-gallery").slick({
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: false,
      nextArrow: "#nxt-btn",
      prevArrow: "#prev-btn",
      responsive: [{
        breakpoint: 800,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }]
    });

    if ($('#textEffect').length > 0) {
      app.textEffect();
    }

    if ($('#trigger-comments').length > 0) {
      app.showComments();
    }
  }
};

$(document).ready(function () {
  app.init();
});